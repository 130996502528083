import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/imodium-behandelen-diarree/imodium-instant/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/medicaments-imodium/imodium-instant/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="f1ad4f3aa074f003779b27a45b4a570de898cf40"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/imodium-behandelen-diarree/">
                  {" "}
                  IMODIUM® Geneesmiddelen{" "}
                </a>
              </li>
              <li className="active">
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  IMODIUM® Instant
                </a>
              </li>
              <li>
                <a href="/imodium-behandelen-diarree/imodium-duo/">
                  IMODIUM® Duo
                </a>
              </li>
              <li>
                <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
              
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Als het gaat om de behandeling van de symptomen van diarree,
                  zijn er nog veel onzekerheden. Lees enkele tips.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-42">
            <h1>IMODIUM® Instant orodispergeerbare tabletten</h1>
            <div className="row">
              <div className="six columns">
                <img
                  alt="IMODIUM®  Instant (loperamide) handige hulp bij behandeling diarree en reizigersdiarree"
                  src="/assets/files/products/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_NL.png"
                  style={{
                    width: "322px",
                    height: "178px"
                  }}
                />
              </div>
              <div className="six columns">
                <h3>IMODIUM® Instant: handige hulp bij diarree</h3>
                <ul>
                  <li>
                    Makkelijk zonder water en zonder slikken in te nemen.
                  </li>
                  <li>Smelt in enkele seconden op je tong.</li>
                  <li>Werkt snel en doeltreffend.</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <p>
                <a
                  href="http://bijsluiters.fagg-afmps.be/?localeValue=nl"
                  target="_blank"
                >
                  <img
                    alt="Bijsluiter IMODIUM® Instant downloaden"
                    src="/assets/files/pages/bijlsuiter.jpeg"
                    style={{
                      width: "48px",
                      height: "47px"
                    }}
                  />{" "}
                  Bijsluiter IMODIUM® Instant downloaden
                </a>
              </p>
              <h3>Gebruik van IMODIUM®</h3>
              <p>
                IMODIUM® Instant bevat 2 mg loperamide. Het wordt gebruikt bij
                de symptomatische behandeling van acute diarree (plotselinge,
                kortdurende) en chronische diarree van diverse oorsprong.
              </p>
              <h4>Instant bij diarree</h4>
              <p>
                IMODIUM<sup>®</sup> Instant&nbsp;op basis van loperamide
                vermindert de overmatige darmactiviteit, zodat de stoelgang weer vaster wordt en je diarree vermindert.
              </p>
              <p>
                Zo wordt uitdroging door verlies van vocht en electrolyten
                beperkt.&nbsp;IMODIUM® Instant kan ook aanbevolen worden bij
                diarree op reis (of kortweg reizigersdiarree). Hierbij moet een
                behandeling van 2 dagen volstaan.&nbsp;
              </p>
              <p>
                IMODIUM® Instant is bijzonder handig bij behandeling van de
                symptomen van diarree als je moeite hebt met het slikken van
                tabletten of als je onderweg bent (reizigersdiarree). Doordat de
                tablet met muntsmaak reeds in enkele seconden in de mond smelt,
                kan je Imodium Instant ook zonder problemen nemen wanneer de
                diarree gepaard gaat met misselijkheid.
              </p>
              <p>
                IMODIUM® instant smelttablet&nbsp; voor de behandeling van de
                symptomen van diarree bestaat als verpakking met 20 of 60
                orodispergeerbare tabletten. Het middel is in de apotheek zonder
                voorschrift te verkrijgen.
              </p>
              <h4>
                IMODIUM® Instant smelttablet, 2 mg orodispergeerbare tabletten
              </h4>
              <p>
                IMODIUM® instant smelttablet is geschikt voor volwassenen en
                kinderen vanaf 6 jaar. Bevat loperamide. Lees altijd de
                bijsluiter.
              </p>
              <p>
                De IMODIUM® Instant tabletten smelten zonder extra water op je
                tong.
              </p>
              <h3>
                <img
                  alt="Werkzame stof van IMODIUM® Instant:"
                  src="/assets/files/pages/werkzame-stof.jpeg"
                  style={{
                    width: "26px",
                    height: "26px"
                  }}
                />
                &nbsp;Werkzame stof van IMODIUM® Instant:
              </h3>
              <p>
                De werkzame stof van IMODIUM® Instant voor symtomatische
                behandeling van diarree en de symptomatische&nbsp;
                <strong>behandeling van reizigersdiarree</strong> (diarree op
                reis) is{" "}
                <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                  loperamide
                </a>{" "}
                (Loperamidehydrochloride).
              </p>
              <p>&nbsp;</p>
              <h3>
                <img
                  alt="Toepassingsgebied IMODIUM® Instant:"
                  src="/assets/files/pages/toepassingsgebied.jpeg"
                  style={{
                    width: "26px",
                    height: "26px"
                  }}
                />
                &nbsp;Toepassingsgebied IMODIUM® Instant:
              </h3>
              <p>
                Symptomatische behandeling van acute diarree en chronische
                diarree van diverse oorsprong. Kan aanbevolen worden bij
                reizigersdiarree. Bij reizigersdiarree moet een behandeling van
                2 dagen volstaan.
              </p>
              <p>&nbsp;</p>
              <h4>Posologie of dosering van IMODIUM® Instant:</h4>
              <ul>
                <li>
                  <strong>
                    Dosering IMODIUM® Instant voor volwassenen:&nbsp;
                  </strong>
                  Aanvangsdosis 2 orodispergeerbare tabletten. Dan zo nodig 1
                  orodispergeerbare tablet na elke volgende zachte stoelgang.
                  Neem nooit meer dan 8 orodispergeerbare tabletten in een
                  periode van 24 uur.
                  <br />
                  &nbsp;
                </li>
                <li>
                  <strong>
                    Dosering IMODIUM® Instant voor kinderen vanaf 6 jaar:&nbsp;
                  </strong>
                  Aanvangsdosis 1 orodispergeerbare tablet. Dan zo nodig 1
                  orodispergeerbare tablet na elke volgende zachte stoelgang. en
                  maximale dagdosis 3 orodispergeerbare tabletten per 20 kg.
                  Neem nooit meer dan 8 orodispergeerbare tabletten in een
                  periode van 24 uur.
                  <br />
                  &nbsp;
                </li>
                <li>
                  <strong>Behandelingsduur</strong>: Gebruik het geneesmiddel
                  zonder medisch advies niet langer dan 2 dagen.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>                        
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Michel de Braeystraat 52, 2000 Antwerpen, België. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
